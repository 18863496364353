import { useEffect } from 'react'
import Router from 'next/router'
import { appStore } from '../src/common/AppStore'
import { SCOPES } from '../src/config/constants'
import { useAuth } from '../src/common/utils/hooks/useAuth'

export default () => {
  const { isLoading } = useAuth(SCOPES.Client) // just random scope to fire loading of user accesses

  useEffect(() => {
    if (isLoading) return

    const lastEntityId = appStore.getLastEntityIdFromLocalStorage() || ''
    const scope = appStore.getScopeFromId(lastEntityId)

    if (lastEntityId && scope) {
      if (scope === SCOPES.Client) {
        Router.push('/client')
      } else if (scope === SCOPES.Holder) {
        Router.push('/end-user')
      }
    } else if (appStore.getIdInScope(SCOPES.Client)) {
      Router.push('/client')
    } else if (appStore.getIdInScope(SCOPES.Holder)) {
      Router.push('/end-user')
    }
  }, [isLoading])

  return null
}
